<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--health--add">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <BtnBack />
          <h1 class="page-title">
            {{ pageTitle }}
          </h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>

            <h2 class="section-title">Désignation</h2>

            <div class="form grid">
              <div class="tmp-col">
                <Input id="nom" label="Nom générique" />
                <Input v-if="this.helperService.userHasProfil('super-administrateur')" id="euid" label="EUID" />
              </div>
              <div class="col--fullwidth">
                <!-- Noms commerciaux -->
                <RepeatableInputs v-if="repeatables.noms_commerciaux && repeatables.noms_commerciaux.default"
                  label="Noms commerciaux" id="noms_commerciaux"
                  :default-selection="repeatables.noms_commerciaux.default" :errors="errors"
                  :items="repeatables.noms_commerciaux.items" />
                <RepeatableInputs v-else-if="repeatables.noms_commerciaux" label="Noms commerciaux"
                  id="noms_commerciaux" :errors="errors" :items="repeatables.noms_commerciaux.items" />
                <!-- Fin Noms commerciaux -->
              </div>
              <div class="tmp-col">
                <Input id="code_cooperative" label="Code Coop" />
              </div>
              <div class="tmp-col">
                <Input id="code_bpe" label="Code BPE" />
              </div>
              <div class="tmp-col">
                <Input id="code_invivo" label="Code Invivo" />
              </div>
            </div>

          </Container>
        </Section>
        <Section class="bg-gray-lightestest section--border-top">
          <Container>

            <h2 class="section-title">Fiche d'identité</h2>

            <div class="form grid">
              <div class="tmp-col">
                <SelectExtended id="sous_thematiques" label="Sous-thèmes" optionKey="uid" optionValue="nom"
                  groupLabel="designation" groupValues="sous_thematiques" apiEndpoint="dictionnaire/thematique"
                  :groupSelect="true" multiple />
              </div>
              <div class="tmp-col">
                <Input id="numero_amm" label="AMM" />
              </div>
              <div class="tmp-col">
                <SelectExtended id="famille_uid" label="Famille de produits" optionKey="uid" optionValue="nom"
                  :apiEndpoint="'dictionnaire/famille/produit'" />
              </div>
              <div class="tmp-col">
                <SelectExtended v-if="form.references.formulation" id="formulation_id" label="Formulation"
                  option-key="id" option-value="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${form.references.formulation.id}/valeur`" />
              </div>
              <div class="tmp-col">
                <SelectExtended v-if="form.references.famillechimique" id="famille_chimique_id" label="Famille chimique"
                  option-key="id" option-value="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${form.references.famillechimique.id}/valeur`" />
              </div>
              <div class="tmp-col">
                <SelectExtended id="unite_uid" label="Unité" option-key="uid" option-value="valeur"
                  apiEndpoint="unite" />
              </div>
              <div class="col--fullwidth">
                <!-- Composants -->
                <RepeatableInputs v-if="repeatables.composants && repeatables.composants.default" label="Composition"
                  id="composants" :default-selection="repeatables.composants.default" :errors="errors"
                  :items="repeatables.composants.items" />
                <RepeatableInputs v-else-if="repeatables.composants" label="Composition" id="composants"
                  :errors="errors" :items="repeatables.composants.items" />
                <!-- Fin Composants -->
              </div>
              <div class="tmp-col">
                <Radio id="biocontrole" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'NA', value: null },
                ]" label="Biocontrôle" inputStyle="inline" />
              </div>
              <div class="tmp-col">
                <Radio id="agriculturebiologique" :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                  { label: 'NA', value: null },
                ]" label="Utilisable en agriculture biologique" inputStyle="inline" />
              </div>
              <div class="tmp-col">
                <Radio id="actif" required :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ]" label="Statut actif" inputStyle="inline" @change="changeActif" />
              </div>
            </div>

          </Container>
        </Section>
        <Section class="section--border-top">
          <Container>

            <h2 class="section-title">Homologation</h2>

            <!-- Doses -->
            <RepeatableInputs label="Homologation" v-if="repeatables.doses_health && repeatables.doses_health.default"
              id="doses" :default-selection="repeatables.doses_health.default" :errors="errors"
              :items="repeatables.doses_health.items" :n-cols="4" />
            <RepeatableInputs v-else-if="repeatables.doses_health" label="Homologation" id="doses" :errors="errors"
              :items="repeatables.doses_health.items" :n-cols="4" />
            <!-- Fin Doses -->

          </Container>
        </Section>
        <Section class="bg-gray-lightestest section--border-top">
          <Container>

            <h2 class="section-title">Pictogrammes</h2>

            <SelectExtended id="pictogrammes" label="Pictogrammes" option-key="media" option-value="nom"
              apiEndpoint="pictogramme" chipsImages multiple />

          </Container>
        </Section>
        <Section class="section--border-top">
          <Container>

            <h2 class="section-title">Validité</h2>

            <div class="form grid">
              <div class="tmp-col">
                <Input type="date" id="date_fincommercialisation" label="Date de fin période de commercialisation" />
              </div>
              <div class="tmp-col">
                <Input type="date" id="date_finutilisation" label="Date de fin période d'utilisation" />
              </div>
            </div>
          </Container>
        </Section>
        <Section class="bg-gray-lightestest section--border-top">
          <Container>
            <h2 class="section-title">Remarques</h2>
            <Input type="textarea" id="remarques" placeholder="Remarques..." />
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'healths' }" />
          <Btn v-if="method === 'put'" v-show="helperService.userHasPermission('produit_remove') && method === 'put'"
            hollow @click="modalDeleteProduct = true" text="Supprimer" />
          <Btn v-show="helperService.userHasPermission('produit_add')
            || helperService.userHasPermission('produit_edit')" btnType="submit"
            :text="method === 'post' ? 'Enregistrer' : 'Modifier'" color="primary" />
        </div>
      </div>
    </div>

    <!-- Modals -->
    <Modal v-if="$route.params.id && datas.product" title="Supprimer le produit&nbsp;?" :active="modalDeleteProduct"
      :data="datas.product" @modal-close="modalDeleteProduct = false">
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer le produit
          <b>{{ data.nom }}</b>?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modalDeleteProduct = false" />
        <Btn text="Supprimer" @click="deleteProduct()" icon="trash-alt" color="primary" />
      </template>
    </Modal>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Modal from '@/components/layout/Modal.vue'
import BtnBack from '@/components/base/BtnBack.vue'

export default {
  name: 'HealthAddView',

  components: {
    BtnBack,
    Modal,
    Btn,
    Input,
    Section,
    Container,
    SelectExtended,
    Radio,
    RepeatableInputs,
  },

  props: {
    pageTitle: {
      type: String,
    },
    typeUid: {
      type: String,
      default: 'SANTEVEGETALE',
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      // Commun
      actif: this.yup.boolean().required(),
      remarques: this.yup.string().nullable(),
      code_cooperative: this.yup.string().nullable(),
      nom: this.yup.string().nullable(),
      euid: this.yup.string().nullable(),
      noms_commerciaux: this.yup.array().of(
        this.yup.object().shape({
          fournisseur_uid: this.yup.object().nullable(),
          nom: this.yup.string().nullable(),
          code_interne: this.yup.string().when(
            ['fournisseur_uid', 'nom'],
            // eslint-disable-next-line max-len
            (fournisseur, nom, schema) => (fournisseur && nom === '' ? schema.required() : schema.nullable()),
          ),
        }),
      ).nullable(),
      sous_thematiques: this.yup.array().nullable(),
      pictogrammes: this.yup.array().nullable(),
      unite_uid: this.yup.object().nullable(),

      // Santé végétale
      code_invivo: this.yup.string().nullable(),
      code_bpe: this.yup.string().nullable(),
      famille_chimique_id: this.yup.object().nullable(),
      numero_amm: this.yup.string().nullable(),
      formulation_id: this.yup.object().nullable(),
      date_fincommercialisation: this.yup.string().nullable(),
      date_finutilisation: this.yup.string().nullable(),
      biocontrole: this.yup.boolean().nullable(),
      agriculturebiologique: this.yup.boolean().nullable(),
      doses: this.yup.array().of(
        this.yup.object().shape({
          dose: this.yup.string().nullable(),
          unite_uid: this.yup.object().when(
            ['dose'],
            (dose, schema) => (dose ? schema.required().nullable() : schema.nullable()),
          ),
          culture_uid: this.yup.object().when(
            ['dose'],
            (dose, schema) => (dose ? schema.required().nullable() : schema.nullable()),
          ),
          cible_id: this.yup.object().when(
            ['dose'],
            (dose, schema) => (dose ? schema.required().nullable() : schema.nullable()),
          ),
        }),
      ).nullable(),
      composants: this.yup.array().of(
        this.yup.object().shape({
          uid: this.yup.object().nullable(),
          quantite: this.yup.string().when(
            ['id'],
            (id, schema) => (id ? schema.required().nullable() : schema.nullable()),
          ),
          unite_uid: this.yup.object().when(
            ['id'],
            (id, schema) => (id ? schema.required().nullable() : schema.nullable()),
          ),
        }),
      ).nullable(),
      famille_uid: this.yup.object().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      if (values.noms_commerciaux && values.noms_commerciaux.length > 0) {
        let counter = 0
        values.noms_commerciaux.forEach((element) => {
          if (
            (
              element.code_interne !== null || element.nom !== null
              || element.code_interne !== '' || element.nom !== ''
            )
            && !element.fournisseur_uid
          ) {
            this.formService.setFieldError('noms_commerciaux', 'Le champ fournisseur est obligatoire')
            this.emitter.emit('alert', { type: 'error', content: 'Le champ fournisseur est obligatoire.' })
            counter += 1
          }
        })
        if (counter === 0) {
          this.handleItem(values)
        }
      } else {
        this.handleItem(values)
      }
    })

    return {
      id: null,
      form: {
        entites: [],
        thematique: {},
        references: [],
        pictos: [],
        actif: true,
      },
      modalDeleteProduct: false,
      repeatables: {},
      endpoint: 'produit',
      method: 'post',
      datas: {},
      params: {},
      schema: {},
      submitErrors: false,
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  async created() {
    this.form.entites = await this.productService.getEntities(true)
    this.id = this.$route.params.id
    this.form.references = await this.productService.getReferenceByUids('formulation', 'famillechimique', 'cible')
    this.form.thematique = await this.productService.getThematique(this.typeUid)
    this.form.pictos = await this.productService.getPictos()
    this.repeatables = this.productService.getRepeatables(this.form)
    if (this.id) {
      this.endpoint = `produit/${this.id}`
      this.method = 'put'
      this.getProduct()
      this.getDoses(this.id)
    } else {
      this.schema = this.formService.populateShema({ actif: true })

      this.formService.setFormValues(this.schema)
    }
  },

  unmounted() {
    this.form = {}
    this.repeatables = {}
  },

  methods: {
    changeActif() {
      this.schema.actif = !this.schema.actif
    },
    containNull(array) {
      let response = true
      if (array && array.length === 1) {
        response = !Object.values(array[0]).some((v) => v === null)
      }
      return response
    },
    deleteProduct() {
      this.productService.delete(this.datas.product.id, 'healths')

      this.modalDeleteProduct = false
    },
    getProduct() {
      this.fetchService.get(`${this.endpoint}`).then((response) => {
        this.datas.product = response.data

        if (this.datas.product.entite.id !== this.$store.state.auth.user.data.entite.id) {
          this.$router.push({ name: 'forbidden' })
        }

        this.schema = this.formService.populateShema(this.datas.product)
        this.form.actif = this.schema.actif

        this.repeatables = this.productService
          .setDatas(this.datas)
          .setRepeatables(this.repeatables)
          .getRepeatablesFromProduct('composants', 'noms_commerciaux', 'doses_health')

        this.schema.famille_chimique_id = {
          key: this.datas.product.famille_chimique?.id,
          value: this.datas.product.famille_chimique?.valeur,
        }

        this.schema.famille_uid = {
          key: this.datas.product.famille?.uid,
          value: this.datas.product.famille?.nom,
        }

        this.schema.formulation_id = {
          key: this.datas.product.formulation?.id,
          value: this.datas.product.formulation?.valeur,
        }

        this.schema.unite_uid = {
          key: this.datas.product.unite?.uid,
          value: this.datas.product.unite?.valeur,
        }

        this.schema.sous_thematiques = this.datas.product.sous_thematiques.map((thematique) => ({
          key: thematique?.uid,
          value: thematique?.nom,
        }))

        this.schema.pictogrammes = this.datas.product.pictogrammes.map((picto) => ({
          key: picto.media?.id,
          uid: picto?.uid,
          value: picto?.nom,
        }))

        this.schema.date_finutilisation = this.datas.product.date_finutilisation?.split(' ').shift()
        this.schema.date_fincommercialisation = this.datas.product.date_fincommercialisation?.split(' ').shift()

        this.formService.setFormValues(this.schema)
      })
    },

    getDoses(productId) {
      this.fetchService.get(`produit/${productId}/dose`).then((r) => {
        this.datas.doses = r.data
      })
    },

    async handleItem(values) {
      // Traitement des données saisies et complément
      const postParams = values

      if (postParams.noms_commerciaux && postParams.noms_commerciaux.length > 0) {
        postParams.noms_commerciaux.forEach((element) => {
          if ((element.code_interne !== '' || element.nom !== '') && !element.fournisseur_uid) {
            this.formService.setFieldError('noms_commerciaux', 'oups')
            this.emitter.emit('alert', { type: 'error', content: 'Le champ fournisseur est obligatoire.' })
          }
        })
      }

      Object.keys(postParams).forEach((param) => {
        if (typeof postParams[param] === 'object') {
          if (param === 'pictogrammes') {
            if (postParams[param] !== null) {
              postParams[param] = this.productService.sanitizeParams(postParams[param], true)
            }
          } else if (postParams[param] !== null) {
            postParams[param] = this.productService.sanitizeParams(postParams[param])
          }
        }
        if (postParams[param] === '') {
          postParams[param] = null
        }
      })

      postParams.entite_id = this.form.entites.entite.id
      postParams.type_uid = this.$props.typeUid
      postParams.date_finutilisation = this.helperService
        .formatDateForApi(postParams?.date_finutilisation)
      postParams.date_fincommercialisation = this.helperService
        .formatDateForApi(postParams?.date_fincommercialisation)

      if (!postParams.pictogrammes) {
        postParams.pictogrammes = []
      }

      if (postParams.sous_thematiques && postParams.sous_thematiques.length) {
        postParams.sous_thematiques = postParams.sous_thematiques.map(
          (thematique) => ({ uid: thematique.key || thematique.uid }),
        )
      }

      this.fetchService[this.method](this.endpoint, postParams).then(
        (response) => {
          const { id } = response.data

          // Traitement des doses
          // RECUP doses
          this.getDoses(id)

          if (postParams.doses && postParams.doses.length > 0) {
            const { dosesCreated, idsDosesDeleted, dosesUpdated } = this.productService
              .getDosesToStore(
                postParams,
                this.datas.doses || [],
              )

            dosesCreated.forEach((dose) => {
              // eslint-disable-next-line no-param-reassign
              this.fetchService.post(`produit/${id}/dose`, dose).then(
                () => {
                  this.getDoses(id)
                  console.log('Dose créée')
                },
                (cde) => {
                  this.formService.handleApiError(cde)
                  this.submitErrors = true
                },
              )
              return this.datas.doses
            })

            idsDosesDeleted.forEach((did) => {
              this.fetchService.delete(`produit/${id}/dose/${did}`).then(
                () => {
                  this.getDoses(id)
                  console.log(`Dose id : ${did}supprimée`)
                },
                (dde) => {
                  this.formService.handleApiError(dde)
                },
              )
              return this.datas.doses
            })

            dosesUpdated.forEach((dose) => {
              this.fetchService.put(`produit/${id}/dose/${dose.id}`, dose).then(
                () => {
                  this.getDoses(id)
                  console.log(`Dose id : ${dose.id}modifiée`)
                },
                (ude) => {
                  this.formService.handleApiError(ude)
                },
              )
              return this.datas.doses
            })
          }
          // Fin du traitement des doses

          // MAJ composition
          // TODO voir ce que dit Louis à propos du ticket créé sur
          // la mise à jour des compos quand on les supprime toutes
          if (postParams.composants) {
            postParams.composants = postParams.composants.filter(
              (composant) => composant.uid !== null
                && composant.quantite !== null
                && composant.unite_uid !== null,
            )

            this.fetchService.put(
              `produit/${id}/composition`,
              { composants: postParams.composants || [] },
            ).then(
              (resolve) => {
                this.datas.composants = resolve.data
                console.log('Composition mise à jour')
              },
              (reject) => {
                this.formService.handleApiError(reject)
              },
            )
          }

          // Fin MAJ Composition

          // Traitement des noms_commerciaux
          if (postParams.noms_commerciaux && postParams.noms_commerciaux.length > 0) {
            const { namesCreated, idsNamesDeleted, namesUpdated } = this.productService
              .getNamesToStore(
                postParams,
                this.repeatables.noms_commerciaux.default || [],
              )

            namesCreated.forEach((name) => {
              // eslint-disable-next-line no-param-reassign
              name.produit_id = id

              if (name.nom === '' || name.nom === null) {
                // eslint-disable-next-line no-param-reassign
                name.nom = null
              }

              if (name.code_interne || name.fournisseur_uid || name.nom) {
                this.fetchService.post('nom_commercial', name).then(
                  () => {
                    console.log('Nom commercial créé')
                  },
                  (cne) => {
                    this.formService.handleApiError(cne)
                  },
                )
              }
            })

            idsNamesDeleted.forEach((nid) => {
              this.fetchService.delete(`nom_commercial/${nid}`).then(
                () => {
                  console.log(`Nom commercial id : ${nid} supprimé`)
                },
                (dne) => {
                  this.formService.handleApiError(dne)
                },
              )
            })

            namesUpdated.forEach((name) => {
              // eslint-disable-next-line no-param-reassign
              name.produit_id = id

              if (name.nom === '' || name.nom === null) {
                // eslint-disable-next-line no-param-reassign
                name.nom = null
              }

              this.fetchService.put(`nom_commercial/${name.id}`, name).then(
                () => {
                  console.log(`Nom commercial id : ${name.id} modifié`)
                },
                (une) => {
                  this.formService.handleApiError(une)
                },
              )
            })
          }
          // Fin noms_commerciaux
          this.emitter.emit('alert', {
            type: 'success',
            content: this.method === 'post' ? 'Le produit a bien été créé.' : 'Le produit a bien été modifié.',
          })

          this.$router.push({ name: 'healths' })
        },
      ).catch((e) => this.formService.handleApiError(e))
    },
  },
}
</script>
